// Navbar.js
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Button, Badge } from "@chakra-ui/react";
import { CartContext } from "./CartContext";

function Navbar() {
  const { cartItems } = useContext(CartContext);

  return (
    <Box bg="gray.100" px={4}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <Link to="/">
          <Box fontWeight="bold">Amazon Scraper App</Box>
        </Link>
        <Flex alignItems={"center"}>
          <Link to="/">
            <Button variant="ghost" mr={4}>
              Home
            </Button>
          </Link>
          <Link to="/products">
            <Button variant="ghost" mr={4}>
              Products
            </Button>
          </Link>
          <Link to="/scraper">
            <Button variant="ghost" mr={4}>
              Scraper
            </Button>
          </Link>
          <Link to="/cart">
            <Button variant="ghost" position="relative">
              Cart
              {cartItems.length > 0 && (
                <Badge
                  colorScheme="red"
                  borderRadius="full"
                  position="absolute"
                  top="0"
                  right="0"
                  transform="translate(50%, -50%)"
                >
                  {cartItems.length}
                </Badge>
              )}
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Navbar;
