// Home.js
import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

function Home() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading>Welcome to the Amazon Scraper App</Heading>
      <Text mt={4}>Use the navigation bar to access different pages.</Text>
    </Box>
  );
}

export default Home;
