import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Heading,
  Image,
  Text,
  Spinner,
  SimpleGrid,
  Stack,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CartContext } from './CartContext';

function ProductDetails() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `https://shop-amzn-api.onrender.com/fetch-product/${id}`
        );
        setProduct(response.data.product);
      } catch (error) {
        console.error('Error fetching product:', error);
        setProduct(null);
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [id]);

  if (loading) {
    return (
      <Box textAlign="center" py={10} px={6}>
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!product) {
    return (
      <Box textAlign="center" py={10} px={6}>
        <Heading>Product not found</Heading>
      </Box>
    );
  }

  const variant = product.variants[0];

  return (
    <Box py={10} px={6} maxW="1200px" mx="auto" >
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Box>
          <Image
            src={product.images[0]?.src || 'https://via.placeholder.com/500'}
            alt={product.title}
            objectFit="cover"
            borderRadius="md"
            width="100%"
          />
          <Stack direction="row" mt={4} spacing={2}>
            {product.images.map((image) => (
              <Image
                key={image.id}
                src={image.src}
                alt={product.title}
                objectFit="cover"
                borderRadius="md"
                height="100px"
                width="100px"
              />
            ))}
          </Stack>
        </Box>
        <Box>
          <Heading mb={4}>{product.title}</Heading>
          <Text
            dangerouslySetInnerHTML={{ __html: product.body_html }}
            mb={4}
          />

          <Text fontSize="2xl" fontWeight="bold" mb={2}>
            Price: ${variant.price}
          </Text>
          <Text fontSize="lg" mb={2}>
            SKU: {variant.sku}
          </Text>
          <Text fontSize="lg" mb={2}>
            Vendor: {product.vendor}
          </Text>
          <Text fontSize="lg" mb={2}>
            Product Type: {product.product_type}
          </Text>

          <Box mb={4}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Quantity:
            </Text>
            <NumberInput
              min={1}
              value={quantity}
              onChange={(valueString) =>
                setQuantity(parseInt(valueString) || 1)
              }
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>

          <Button
            colorScheme="teal"
            mt={4}
            onClick={() => addToCart(product.id, quantity)}
          >
            Add to Cart
          </Button>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default ProductDetails;
