// Scraper.js
import React, { useState } from 'react';
import {
  Box,
  Input,
  Button,
  Textarea,
  Heading,
  VStack,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';

function Scraper() {
  const [amazonUrl, setAmazonUrl] = useState('');
  const [jsonResponse, setJsonResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [addingProduct, setAddingProduct] = useState(false);
  const toast = useToast();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://shop-amzn-api.onrender.com/fetch-amazon-data', {
        amazonUrl,
      });
      setJsonResponse(JSON.stringify(response.data, null, 2));
    } catch (error) {
      setJsonResponse('Error fetching data');
      console.error(error);
    }
    setLoading(false);
  };

  const handleAddProduct = async () => {
    setAddingProduct(true);
    try {
      const productData = JSON.parse(jsonResponse);
      await axios.post('https://shop-amzn-api.onrender.com/add-product', productData);
      toast({
        title: 'Product added successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      // Clear all inputs after adding the product
      setAmazonUrl('');
      setJsonResponse('');
    } catch (error) {
      console.error('Error adding product:', error);
      toast({
        title: 'Error adding product.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setAddingProduct(false);
    }
  };

  return (
    <Box textAlign="center" py={10} px={6} maxW="400px" mx="auto">
      <VStack spacing={5}>
        <Heading>Amazon Scraper</Heading>
        <Input
          value={amazonUrl}
          onChange={(e) => setAmazonUrl(e.target.value)}
          placeholder="Enter Amazon Product URL"
          size="md"
          width="400px"
        />
        <Button
          onClick={handleSubmit}
          isLoading={loading}
          loadingText="Fetching Data"
          colorScheme="teal"
          width="400px"
        >
          Get Amazon Data
        </Button>
        <Textarea
          value={jsonResponse}
          readOnly
          placeholder="Amazon data will be displayed here"
          size="sm"
          height="300px"
          width="400px"
          resize="none"
        />
        <Button
          onClick={handleAddProduct}
          colorScheme="blue"
          isDisabled={!jsonResponse || jsonResponse === 'Error fetching data'}
          isLoading={addingProduct}
          loadingText="Adding Product"
          width="400px"
        >
          Add Product
        </Button>
      </VStack>
    </Box>
  );
}

export default Scraper;
