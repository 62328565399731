// Products.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Grid,
  Image,
  Text,
  LinkBox,
  LinkOverlay,
  Spinner,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Products() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect to fetch products on component mount
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://shop-amzn-api.onrender.com/fetch-products');
        setProducts(response.data.products);
      } catch (error) {
        console.error('Error fetching products:', error);
        setProducts([]);
      }
      setLoading(false);
    };
    fetchProducts();
  }, []);

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading mb={6}>Products</Heading>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={6}>
          {products.length > 0 ? (
            products.map((product) => (
              <LinkBox
                key={product.id}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                _hover={{ shadow: 'md' }}
              >
                <Image
                  src={product.images[0]?.src || 'https://via.placeholder.com/250'}
                  alt={product.title}
                  objectFit="cover"
                  height="200px"
                  width="100%"
                />
                <Box p="6">
                  <Box d="flex" alignItems="baseline">
                    <LinkOverlay as={Link} to={`/products/${product.id}`}>
                      <Heading fontSize="xl">{product.title}</Heading>
                    </LinkOverlay>
                  </Box>
                  <Text mt={2} color="gray.600" isTruncated>
                    {product.body_html.replace(/<[^>]+>/g, '')}
                  </Text>
                </Box>
              </LinkBox>
            ))
          ) : (
            <Text>No products found.</Text>
          )}
        </Grid>
      )}
    </Box>
  );
}

export default Products;
