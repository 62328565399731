import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Image,
  Spinner,
  HStack,
  Divider,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import axios from 'axios';
import { CartContext } from './CartContext';
import { Link } from 'react-router-dom';

function Cart() {
  const { cartItems, removeFromCart, updateCartItemQuantity, clearCart } = useContext(CartContext);
  const [cartProducts, setCartProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  useEffect(() => {
    const fetchCartProducts = async () => {
      try {
        const productPromises = cartItems.map((item) =>
          axios.get(`https://shop-amzn-api.onrender.com/fetch-product/${item.productId}`)
        );
        const productResponses = await Promise.all(productPromises);
        const productsData = productResponses.map((res) => res.data.product);

        const cartItemsData = productsData.map((product) => {
          const variant = product.variants[0];
          const cartItem = cartItems.find(
            (item) => item.productId === product.id
          );
          const quantity = cartItem ? cartItem.quantity : 1;
          return { variant, product, quantity };
        });

        setCartProducts(cartItemsData);
      } catch (error) {
        console.error('Error fetching cart products:', error);
      } finally {
        setLoading(false);
      }
    };

    if (cartItems.length > 0) {
      fetchCartProducts();
    } else {
      setCartProducts([]);
      setLoading(false);
    }
  }, [cartItems]);

  const total = cartProducts
    .reduce(
      (sum, item) => sum + parseFloat(item.variant.price || 0) * item.quantity,
      0
    )
    .toFixed(2);

  const initiateCheckout = async () => {
    setCheckoutLoading(true);
    try {
      const encodeShopifyId = (resource, id) => {
        const gid = `gid://shopify/${resource}/${id}`;
        return btoa(gid);
      };

      const lineItems = cartProducts.map((item) => ({
        variantId: encodeShopifyId('ProductVariant', item.variant.id),
        quantity: item.quantity,
      }));

      const response = await axios.post(
        'https://shop-amzn-api.onrender.com/create-checkout',
        {
          lineItems,
        }
      );

      const { checkoutUrl } = response.data;
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Error initiating checkout:', error);
      alert('Error initiating checkout. Please try again.');
    } finally {
      setCheckoutLoading(false);
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" py={10} px={6}>
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box maxWidth="800px" margin="auto" padding={4}>
      <Heading as="h1" size="xl" marginBottom={6}>
        Shopping Cart
      </Heading>
      {cartProducts.length === 0 ? (
        <Text>Your cart is empty.</Text>
      ) : (
        <>
          <VStack spacing={4} align="stretch">
            {cartProducts.map((item) => (
              <Box
                key={item.product.id}
                borderWidth={1}
                borderRadius="lg"
                padding={4}
              >
                <HStack spacing={4}>
                  <Image
                    src={
                      item.product.images[0]?.src ||
                      'https://via.placeholder.com/100'
                    }
                    alt={item.product.title}
                    boxSize="100px"
                    objectFit="cover"
                  />
                  <Box flex="1">
                    <Link to={`/products/${item.product.id}`}>
                      <Heading as="h2" size="md">
                        {item.product.title}
                      </Heading>
                    </Link>
                    <Text fontSize="lg">
                      Price: ${parseFloat(item.variant.price || 0).toFixed(2)}
                    </Text>
                    <HStack mt={2}>
                      <Text>Quantity:</Text>
                      <NumberInput
                        size="sm"
                        maxW={20}
                        min={1}
                        value={item.quantity}
                        onChange={(valueString) =>
                          updateCartItemQuantity(item.product.id, parseInt(valueString))
                        }
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </HStack>
                    <Text fontSize="lg" mt={2}>
                      Subtotal: $
                      {(
                        parseFloat(item.variant.price || 0) * item.quantity
                      ).toFixed(2)}
                    </Text>
                  </Box>
                  <Button
                    size="sm"
                    colorScheme="red"
                    onClick={() => removeFromCart(item.product.id)}
                  >
                    Remove
                  </Button>
                </HStack>
              </Box>
            ))}
          </VStack>
          <Divider my={6} />
          <Text fontSize="xl" fontWeight="bold" marginTop={6}>
            Total: ${total}
          </Text>
          <Button
            colorScheme="teal"
            mt={4}
            onClick={initiateCheckout}
            isLoading={checkoutLoading}
          >
            Proceed to Checkout
          </Button>
          <Button
            colorScheme="red"
            mt={4}
            ml={4}
            onClick={clearCart}
            variant="outline"
          >
            Clear Cart
          </Button>
        </>
      )}
    </Box>
  );
}

export default Cart;
